import { enumToOptions } from 'utils';

export const VERSION = process.env.REACT_APP_VERSION;
export const ENV = process.env.REACT_APP_ENV;
export const ENTERPRISE_URL = process.env.REACT_APP_ENTERPRISE_URL;
export const INSURANCE_ROLE_ID = process.env.REACT_APP_INSURANCE_ROLE_ID;

export const INTERPRETER_TYPES = {
    internal: 1,
    external: 2
};

export const LANGUAGES = {
    1: 'English',
    2: 'Spanish',
    3: 'French',
    4: 'Other'
};

export const LanguageOptions = enumToOptions(LANGUAGES);

export const isProd = ENV === 'production';
export const isLocal = ENV === 'local';

const ROLE_RN = 6;
const ROLE_RN_PA = 34;
export const ROLES_RN = [ROLE_RN, ROLE_RN_PA]; 
