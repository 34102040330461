/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './Header.scss';
import { Avatar, Watermark } from 'library';
import { ENV, VERSION, isProd } from 'config';
import { useSelector, useDispatch } from 'react-redux';
import { clearCall } from 'store/call';
import { clearLocation } from 'store/location';
import { LogoRN, LogoIC, AvatarRN, AvatarIC } from 'assets';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const watermark = isProd ? '' : ENV;
    const avatar = me.$isRN ? AvatarRN : AvatarIC;
    const logo = me.$isRN ? LogoRN : LogoIC;

    const goHome = () => {
        dispatch(clearCall());
        dispatch(clearLocation());
        navigate('/');
    };

    return (
        <Watermark
            content={watermark}
            fontSize={14}
            gap={[25, 25]}
            color="#ff0000"
        >
            <header>
                <a onClick={goHome}>
                    <img src={logo} alt="Lintelio Logo" />
                    <span>v. {VERSION}</span>
                </a>
                {me?.name && (
                    <h5>
                        <Avatar src={avatar} size={40} />
                        <div>
                            {me?.name}
                            <h6>{me.role?.title}</h6>
                        </div>
                    </h5>
                )}
            </header>
        </Watermark>
    );
};

export { Header };
