import { SELF_REPORT_VALUES } from 'config/Questions';

const transformCreate = (data) => {
    const values = { ...data };
    const isSupervisor = values.selfReportValue === SELF_REPORT_VALUES.NO_THE_SUPERVISOR;
    const isClaimant = values.selfReportValue === SELF_REPORT_VALUES.YES;
    values.fields = {
        ...(values.fields || {}),
        caller_phone: {
            value: values.callerPhone
        },
        caller_first_name: {
            value: values.callerFirstName
        },
        caller_last_name: {
            value: values.callerLastName
        },
        claimant_first_name: {
            value: isClaimant ? values.callerFirstName : null
        },
        claimant_last_name: {
            value: isClaimant ? values.callerLastName : null
        },
        claimant_work_phone: {
            value: isClaimant ? values.callerPhone : null
        },
        claimant_mobile_phone: {
            value: isClaimant ? values.callerPhone : null
        },
        claimant_language_code: { value: values.claimantLanguageCode },
        interpreter_id: { value: values.interpreterId },
        employment_supervisor_first_name: {
            value: isSupervisor ? values.callerFirstName : null
        },
        employment_supervisor_last_name: {
            value: isSupervisor ? values.callerLastName : null
        },
        employment_supervisor_phone: {
            value: isSupervisor ? values.callerPhone : null
        }
    };
    values.origin = 'LAX_IVR';
    return values;
};

export default transformCreate;
