/* eslint-disable max-len */
import { USStateOptions } from 'config/states';
import { formatSsn, formatDateOut, formatDate } from 'utils';

export default {
    source: 'UKG',
    sourceType: 'contact',
    title: 'UKG',
    transform: data => data.sortByString('firstName'),
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            autofill: {
                name: 'claimant_last_name'
            },
            name: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            autofill: {
                name: 'claimant_first_name'
            },
            name: 'firstName',
            width: 80
        },
        {
            label: 'SSN',
            name: 'ssn',
            autofill: {
                name: 'claimant_ssn'
            },
            filterType: 'ssn',
            render: formatSsn,
            width: 120
        },
        {
            label: 'Date of Birth',
            name: 'dob',
            autofill: {
                name: 'claimant_birth_date',
                transform: formatDateOut
            },
            render: formatDate,
            width: 90
        },
        {
            label: 'Employee Id',
            autofill: {
                name: 'claimant_employee_id'
            },
            name: 'employeeId',
            filter: 'employeeId',
            width: 100
        },
        {
            label: 'Email',
            name: 'emailAddress',
            autofill: {
                name: 'claimant_email_address'
            },
            width: 120
        },
        {
            label: 'Home Phone',
            name: 'homePhone',
            autofill: {
                name: 'claimant_mobile_phone',
                editable: true
            },
            width: 150
        },
        {
            label: 'Gender',
            name: 'gender',
            autofill: {
                name: 'claimant_gender_code',
                transform: value => (
                    {
                        M: '1',
                        F: '2'
                    }[value] || undefined)
            },
            width: 60
        },
        {
            label: 'Address',
            name: 'homeAddress',
            autofill: {
                name: 'claimant_address'
            },
            width: 120
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'claimant_city'
            },
            width: 100
        },
        {
            label: 'State',
            autofill: {
                name: 'claimant_state'
            },
            name: 'state',
            filter: 'addressState',
            options: USStateOptions,
            width: 90
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'claimant_zip'
            },
            width: 70
        },
    ]
};
