import React from 'react';
import { Result } from 'antd';

const NotFound = () => (
    <Result
        style={{ marginTop: 100 }}
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
    />
);

export default NotFound;
