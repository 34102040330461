import { externalConctactsApi } from './utils';

class GnContactService {
    static get(contactId) {
        return externalConctactsApi.getExternalcontactsContact(contactId)
            .then(data => ({
                ...data,
                name: `${data.firstName } ${ data.lastName}`
            }));
    }

    static search(options) {
        return externalConctactsApi.getExternalcontactsContacts(options)
            .then(({ entities }) => entities)
            .then(items => items.map(item => ({
                ...item, 
                // eslint-disable-next-line no-nested-ternary
                phoneType: !!item.workPhone ? 'Work' : (!!item.cellPhone ? 'Mobile' : 'Home'),
                phone: item.workPhone?.e164
            })));
    }
    
    static searchByPhone(phone) {
        return GnContactService.search({ q: phone })
            .then(data => data.shift())
            .then(data => ({
                ...data,
                name: `${data?.firstName } ${ data?.lastName}`
            }));
    }
}

export { GnContactService }; 
