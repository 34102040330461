export const QUEUE_ID_IC = process.env.REACT_APP_QUEUE_ID_IC;
export const QUEUE_ID_RN = process.env.REACT_APP_QUEUE_ID_RN;
export const QUEUE_ID_INTERPRETERS = process.env.REACT_APP_QUEUE_ID_INT_INTERPRETERS;
export const GENESYS = {
    environment: process.env.REACT_APP_GENESYS_ENVIRONMENT,
    clientId: process.env.REACT_APP_GENESYS_CLIENT_ID,
    appName: 'Lintelio GC',
};

export const ROUTING_STATUS = {
    OFF_QUEUE: 'OFF_QUEUE', // Not on queue
    IDLE: 'IDLE', // Waiting for a interaction
    INTERACTING: 'INTERACTING', // Handling an interaction (live or wrap up)
    COMMUNICATING: 'COMMUNICATING', // Presence is On Queue, but the user is on a non-ACD interaction, or, if utilization is configured to include non-ACD interactions, the user is OFF_QUEUE and handling a non-ACD interaction
    NOT_RESPONDING: 'NOT_RESPONDING', // An interaction alerted the user, but they did not answer before the timeout
};
/* 
AS: 'American Samoa'
FM: 'Federated States Of Micronesia',
MH: 'Marshall Islands'
MP: 'Northern Mariana Islands'
PW: 'Palau'
*/
export const StateAttributes = [
    'AK - Alaska',
    'AL - Alabama',
    'AR - Arkansas',
    'AZ - Arizona',
    'CA - California',
    'CO - Colorado',
    'CT - Connecticut',
    'DC - District of Columbia',
    'DE - Delaware',
    'FL - Florida',
    'GA - Georgia',
    'GU - Guam',
    'HI - Hawaii',
    'IA - Iowa',
    'ID - Idaho',
    'IL - Illinois',
    'IN - Indiana',
    'KS - Kansas',
    'KY - Kentucky',
    'LA - Louisiana',
    'MA - Massachusetts',
    'MD - Maryland',
    'ME - Maine',
    'MI - Michigan',
    'MN - Minnesota',
    'MO - Missouri',
    'MS - Mississippi',
    'MT - Montana',
    'NC - North Carolina',
    'ND - North Dakota',
    'NE - Nebraska',
    'NH - New Hampshire',
    'NJ - New Jersey',
    'NM - New Mexico',
    'NV - Nevada',
    'NY - New York',
    'OH - Ohio',
    'OK - Oklahoma',
    'OR - Oregon',
    'PA - Pennsylvania',
    'PR - Puerto Rico',
    'RI - Rhode Island',
    'SC - South Carolina',
    'SD - South Dakota',
    'TN - Tennessee',
    'TX - Texas',
    'UT - Utah',
    'VA - Virginia',
    'VI - Virgin Islands',
    'VT - Vermont',
    'WA - Washington',
    'WI - Wisconsin',
    'WV - West Virgina',
    'WY - Wyoming'
];
