/* eslint-disable max-len */
import { formatSsn, formatDate, formatDateOut } from 'utils';
import { USStateOptions } from 'config/states';
import { EsLocationsService } from 'services';

export default {
    source: 'EmployeeBridge',
    sourceType: 'contact',
    title: 'Employbridge',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            name: 'lastName',
            filter: 'lastName',
            required: true,
            autofill: {
                name: 'claimant_last_name',
            },
            width: 120,
        },
        {
            label: 'First Name',
            name: 'firstName',
            filter: 'firstName',
            autofill: {
                name: 'claimant_first_name',
            },
            width: 120,
        },
        {
            label: 'SSN',
            name: 'ssn',
            filter: 'ssn',
            filterType: 'ssn',
            render: formatSsn,
            autofill: {
                name: 'claimant_ssn',
            },
            width: 120
        },
        {
            label: 'Date of Birth',
            name: 'dob',
            filter: 'dob',
            autofill: {
                name: 'claimant_birth_date',
                transform: formatDateOut
            },
            render: formatDate,
            width: 90
        },
        {
            label: 'Employee Id',
            name: 'employeeId',
            filter: 'employeeId',
            autofill: {
                name: 'claimant_employee_id',
            },
            width: 80
        },
        {
            label: 'Hire Date',
            name: 'hireDate',
            filter: 'hireDate',
            render: formatDate,
            autofill: {
                name: 'employment_hired_date'
            },
            width: 90
        },
        {
            label: 'Gender',
            name: 'gender',
            autofill: {
                name: 'claimant_gender_code',
                transform: value => ({ M: '1', F: '2' }[value] || '3'),
            },
            width: 60
        },
        {
            label: 'Address',
            name: 'address1',
            autofill: {
                name: 'claimant_address',
            },
            width: 120
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'claimant_city',
            },
            width: 100
        },
        {
            label: 'State',
            name: 'state',
            options: USStateOptions,
            autofill: {
                name: 'claimant_state',
            },
            width: 90
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'claimant_zip',
            },
            width: 70
        },
    ],
    onSelect: async (item) => {
        const locationNumber = item.thirdParty?.branchCode;

        if (locationNumber) {
            const locations = await EsLocationsService.list({
                locationNumber,
                reportableLocation: 1,
                title: 'Employbridge',
                sortBy: 'title',
                sortDirection: 'asc',
                page: 1,
                perPage: 1
            });
            if (locations.length) {
                return {
                    ...item,
                    locationId: locations[0].id
                };
            }
        }

        return item;
    }
};
