import PropTypes from 'prop-types';
import { InputAddress } from '../../InputAddress';

const InputAutofillAddress = ({ name, ...rest }) => {
    const ident = name?.substring(0, name?.indexOf('_address'));
    return (
        <InputAddress
            autofill={{
                city: ['fields', `${ident}_city`],
                state: ['fields', `${ident}_state`],
                zip: ['fields', `${ident}_zip`],
            }}
            autofillFn={value => ({ value })}
            {...rest}
        />
    );
};

InputAutofillAddress.propTypes = {
    name: PropTypes.string.isRequired,
};

export { InputAutofillAddress };
