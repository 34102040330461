import dayjs from 'dayjs';
import { QUEUE_ID_RN } from 'config';
import { routingApi, analyticsApi, conversationsApi } from './utils';

class GnQueueService {
    static get(id) {
        return routingApi.getRoutingQueue(id);
    }

    static getMembers(queueId) {
        const opts = {
            expand: 'routingStatus',
        };

        return routingApi.getRoutingQueueMembers(queueId, opts)
            .then(data => data.entities.map(item => ({
                ...item,
                ...item.user,
                routingStatus: item.routingStatus.status
            })));
    }

    static async clearQueue(queueId, pageNumber = 1) {
        const startDate = dayjs().subtract(7, 'days').format();
        const endDate = dayjs().format();
        const pageSize = 100;

        const query = {
            paging: {
                pageSize,
                pageNumber
            },
            order: 'DESC',
            segmentFilters: [
                {
                    type: 'and',
                    predicates: [
                        {
                            type: 'dimension',
                            dimension: 'segmentType',
                            operator: 'matches',
                            value: 'interact'
                        },
                        {
                            type: 'dimension',
                            dimension: 'queueId',
                            operator: 'matches',
                            value: queueId
                        },
                        {
                            type: 'dimension',
                            dimension: 'segmentEnd',
                            operator: 'notExists'
                        }
                    ]
                }
            ],
            conversationFilters: [
                {
                    type: 'and',
                    predicates: [
                        {
                            type: 'dimension',
                            dimension: 'conversationEnd',
                            operator: 'notExists'
                        }
                    ]
                }
            ],
            interval: `${startDate}/${endDate}`,

        };

        const { conversations, totalHits } = await analyticsApi
            .postAnalyticsConversationsDetailsQuery(query);

        conversations.forEach(async (item) => {
            await conversationsApi.postConversationDisconnect(item.conversationId);
        });

        if (totalHits > (pageNumber * pageSize)) {
            GnQueueService.clearQueue(queueId, pageNumber + 1);
        }
    }

    static clearQueues() {
        const queueIds = [QUEUE_ID_RN];
        queueIds.forEach((queueId) => {
            GnQueueService.clearQueue(queueId);
        });
    }
}

export { GnQueueService };
