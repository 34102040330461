import dayjs from 'dayjs';
import { PYPESTREAM_LOCATION_ID } from 'config';

export const Rules = {
    required: { required: true, message: 'Required' },
    requiredNoMsg: { required: true, message: '' },
    requiredExt: () => ({
        validator(a, value) {
            const val = value?.value;
            const hasValue = Object.isDefined(val) && (val !== null) && (val !== '');
            if (hasValue || value?.refused || value?.unknown) {
                return Promise.resolve();
            }
            return Promise.reject(Error('Required'));
        },
    }),
    estimated: () => ({
        validator(_, value) {
            if (value?.estimated && !value?.value) {
                return Promise.reject();
            }
            return Promise.resolve();
        },
    }),
    email: {
        pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g,
        message: 'Invalid Email',
    },
    date: () => ({
        validator(_, value) {
            const str = value?.value;
            if (str) {
                const date = dayjs(str);
                if (date < dayjs('1900-01-01')) {
                    return Promise.reject(Error('The date cannot be before 01/01/1900.'));
                }
            }
            return Promise.resolve();
        },
    }),
    noPypestreamLocation: () => ({
        validator(_, value) {
            if (value === PYPESTREAM_LOCATION_ID) {
                return Promise.reject(Error('Location must be updated.'));
            }
            return Promise.resolve();
        },
    }),
    dateInPast: () => ({
        validator(_, value) {
            const str = value?.value;
            if (str && dayjs(str) > dayjs().add(1, 'day')) {
                return Promise.reject(Error('The date cannot be in the future.'));
            }
            return Promise.resolve();
        },
    }),
};
