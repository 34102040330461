import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import call from './call';
import me, { signIn, getEnterpriseMe, getGenesysMe } from './me';
import location from './location';
import incident from './incident';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: signIn.fulfilled,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(getEnterpriseMe());
        listenerApi.dispatch(getGenesysMe());
    },
});

export const store = configureStore({
    reducer: {
        call,
        me,
        location,
        incident
    },
    middleware: getDefault => getDefault()
        .prepend(listenerMiddleware.middleware),
});
