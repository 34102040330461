/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'library';
import { EsLocationsService } from 'services';
import { INCIDENT_TYPES } from 'config/incident';

const initialState = {
    episodeTypeId: INCIDENT_TYPES
};

const getLocation = createAsyncThunk(
    'location/fetch',
    ({ id }) => EsLocationsService.get(id));

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setLocation: (_, action) => ({
            key: action.payload.id,
            label: action.payload.title,
            value: action.payload.id,
            ...action.payload
        }),
        clearLocation: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLocation.pending, (state) => {
                state.$loading = true;
            })
            .addCase(getLocation.fulfilled, (state, action) => ({
                ...action.payload,
                $loading: false
            }))
            .addCase(getLocation.rejected, (state, { error }) => {
                message.error(error.message);
                state.$loading = false;
            });
    }
});

// Action creators are generated for each case reducer function
const { clearLocation, setLocation } = locationSlice.actions;

export {
    getLocation,
    clearLocation, 
    setLocation
};

export default locationSlice.reducer;
