const FIELD_TYPE_AUTOCOMPLETE = 1;
const FIELD_TYPE_DATE = 2;
const FIELD_TYPE_YES_NO = 3;
const FIELD_TYPE_CHECKBOX = 4;
const FIELD_TYPE_DROPDOWN = 5;
const FIELD_TYPE_TIME = 6;
const FIELD_TYPE_CHECKBOX_ARRAY = 7;
const FIELD_TYPE_MEMO = 8;
const FIELD_TYPE_ALERT = 9;
const FIELD_TYPE_ACKNOWLEDGE_ALERT = 10;
const FIELD_TYPE_PLAIN_TEXT = 11;
const FIELD_TYPE_DATE_AUTOFORMAT = 12;
const FIELD_TYPE_DATE_TIME = 13;

export const QUESTION_TYPE = {
    CONFIRM: 'CONFIRM',
    CHECKBOX: 'CHECKBOX',
    CHECKBOXES: 'CHECKBOXES',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    LOOKUP: 'LOOKUP',
    SELECT: 'SELECT',
    TEXT: 'TEXT',
    TEXTAREA: 'TEXTAREA',
    TIME: 'TIME',
    YESNO: 'YESNO'
};

export const QUESTION_TYPE_MAP = {
    [FIELD_TYPE_AUTOCOMPLETE]: QUESTION_TYPE.LOOKUP,
    [FIELD_TYPE_DATE]: QUESTION_TYPE.DATE,
    [FIELD_TYPE_YES_NO]: QUESTION_TYPE.YESNO,
    [FIELD_TYPE_CHECKBOX]: QUESTION_TYPE.CHECKBOX,
    [FIELD_TYPE_DROPDOWN]: QUESTION_TYPE.SELECT,
    [FIELD_TYPE_TIME]: QUESTION_TYPE.TIME,
    [FIELD_TYPE_CHECKBOX_ARRAY]: QUESTION_TYPE.CHECKBOXES,
    [FIELD_TYPE_MEMO]: QUESTION_TYPE.TEXTAREA,
    [FIELD_TYPE_ALERT]: QUESTION_TYPE.CONFIRM,
    [FIELD_TYPE_ACKNOWLEDGE_ALERT]: QUESTION_TYPE.CONFIRM,
    [FIELD_TYPE_PLAIN_TEXT]: QUESTION_TYPE.TEXT,
    [FIELD_TYPE_DATE_AUTOFORMAT]: QUESTION_TYPE.DATE,
    [FIELD_TYPE_DATE_TIME]: QUESTION_TYPE.DATETIME,
};

export const SELF_REPORT_VALUES = {
    YES: 1,
    NO: 2,
    NO_THE_SUPERVISOR: 3
};
