import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { InputText } from '../../InputText';

const InputCallerLocalTime = ({ disabled = false, ...rest }) => {
    const { submitted } = useSelector(state => state.incident);
    return (
        <InputText
            {...rest}
            disabled={disabled || submitted}
        />
    );
};

InputCallerLocalTime.propTypes = {
    disabled: PropTypes.bool
};

export { InputCallerLocalTime };
