import './assets/styles/index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider as AntDConfigProvider, App } from 'antd';
import { store } from 'store';
import { EscapeAntd } from 'library';
import theme from 'assets/theme';
import { APIProvider } from '@vis.gl/react-google-maps';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const GoogleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

root.render((
    <BrowserRouter>
        <Provider store={store}>
            <AntDConfigProvider {...theme}>
                <App notification={{ showProgress: true }}>
                    <APIProvider
                        apiKey={GoogleApiKey}
                        solutionChannel="GMP_linelio_v3_rgmautocomplete"
                    >
                        <EscapeAntd />
                        <Routes />
                    </APIProvider>
                </App>
            </AntDConfigProvider>
        </Provider>
    </BrowserRouter>
));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
