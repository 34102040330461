import { getIncidentType, FLOW_TYPE, INCIDENT_STATE } from 'config';
import configs from 'components/lookups/LookupsClaimant/configs';

const EmptyEnspiriDates = ['0000-00-00', '0000-00-00 00:00:00', '', null];

const dateValue = val => (EmptyEnspiriDates.includes(val) ? undefined : val);

const transformAutofilled = (incident) => {
    const { fields, thirdParty } = incident;
    const thirdPartyContact = thirdParty?.contact;
    if (thirdPartyContact?.source) {
        const config = configs[thirdPartyContact.source];
        if (config) {
            config.columns.forEach((column) => {
                if (column.autofill && !column.autofill.editable) {
                    const field = fields[column.autofill.name];
                    if (field?.value) {
                        let thirdPartyValue = thirdPartyContact[column.name];
                        if (column.autofill.transform) {
                            thirdPartyValue = column.autofill.transform(thirdPartyValue);
                        }
                        // eslint-disable-next-line eqeqeq
                        if (field.value == thirdPartyValue) {
                            fields[column.autofill.name].autofilled = true;
                        }
                    }
                }
            });
        }
    }
    return fields;
};

const transformGet = (data) => {
    if (!data) {
        return undefined;
    }
    const { incidentType } = data;
    const $isFlowTypeIntakeOnly = incidentType?.flowType !== FLOW_TYPE.INTAKE_TRIAGE;
    const localType = getIncidentType(data.episodeTypeId);
    const fields = transformAutofilled(data);

    return {
        ...data,
        fields,
        type: incidentType?.title || localType.tytle,
        injuryDate: dateValue(data.injuryDate),
        incidentType: {
            ...incidentType,
            abbr: localType.abbr
        },
        incidentTypeId: incidentType?.id,
        submitted: !!data.submitted,
        relatedIncident: transformGet(data.relatedIncident),
        $incidentType: localType,
        $isVoided: data.incidentState === INCIDENT_STATE.VOID,
        $isFlowTypeIntakeOnly,
    };
};
export default transformGet;
