/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'library';
import { catchError } from 'utils';
import { GnAuthService, EsAuthService } from 'services';

const initialState = {
    $loading: true,
};

const signIn = createAsyncThunk(
    'me/signIn',
    () => GnAuthService.signIn()
);

const getEnterpriseMe = createAsyncThunk(
    'meEs/load',
    () => EsAuthService.me()
        .catch(catchError)
);

const getGenesysMe = createAsyncThunk(
    'meGn/load',
    () => GnAuthService.me()
);

const meSlice = createSlice({
    name: 'me',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.$loading = true;
            })
            .addCase(signIn.rejected, (state, { error }) => {
                message.error(error.message);
                state.$loading = false;
            })
            .addCase(getGenesysMe.pending, (state) => {
                state.$loading = true;
            })
            .addCase(getGenesysMe.fulfilled, (state, action) => ({
                ...state,
                $genesys: action.payload
            }))
            .addCase(getEnterpriseMe.pending, (state) => {
                state.$loading = true;
            })
            .addCase(getEnterpriseMe.fulfilled, (state, action) => ({
                ...state,
                $loading: false,
                ...action.payload
            }))
            .addCase(getEnterpriseMe.rejected, (state) => {
                state.$loading = false;
            });
    }
});

export {
    signIn,
    getEnterpriseMe,
    getGenesysMe
};

export default meSlice.reducer;
