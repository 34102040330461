import React from 'react';
import { FormItem } from 'library';
import { Rules } from 'utils';
import LocationInput from './LocationInput';

const LookupLocation = props => (
    <FormItem
        name="locationId"
        label="Employer Location"
        rules={[Rules.required]}
        className="required"
    >
        <LocationInput
            {...props}
        />
    </FormItem>
);

export { LookupLocation };
