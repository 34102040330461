import './FormItemDirective.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'library';
import { Rules } from 'utils';
import { useSelector } from 'react-redux';
import Directive from './Directive';

const FormItemDirective = ({
    required = false,
    type,
    isColored = false,
    disabled = false,
    name, label,
    states = [], 
    onFirstCallOnly = 0, 
    ...rest
}) => {
    const incident = useSelector(state => state.incident);
    const location = useSelector(state => state.incident);

    const classNames = [
        'form-item directive',
        `directive-${type}`,
        `${required ? 'required' : ''} `,
        `${isColored ? 'warning' : 'info'}`,
        `${disabled ? 'disabled' : ''}`,
        `form-item-directives-${name}`
    ].join(' ');

    const fieldsState = incident.fields?.employer_location_state?.value;
    const employerState = fieldsState || location.state;
    if (states.length && !states.includes(employerState)) {
        return null;
    }

    const callsCount = incident.calls?.length;
    if (callsCount > 1 && !!onFirstCallOnly) {
        return null;
    }

    return (
        <Form.Item
            className={classNames}
            name={['directives', name]}
            label={<div dangerouslySetInnerHTML={{ __html: label }} />}
            rules={required ? [Rules.required] : []}
            colon={false}
        >
            <Directive
                type={type}
                required={required}
                disabled={disabled}
                {...rest}
            />
        </Form.Item>
    );
};

FormItemDirective.propTypes = {
    required: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    label: PropTypes.string.isRequired,
    isColored: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    states: PropTypes.array,
    onFirstCallOnly: PropTypes.number
};

export { FormItemDirective };
