import api from '../api';
import transformList from './transform.list';

class EsDistributionsService {
    static async list(incidentId, filters) {
        const params = {
            perPage: 50,
            page: 1,
            ...filters
            // orderDirection: 'DESC'
        };

        return api.get(`/api/v2/incidents/${incidentId}/distributions`, params)
            .then(({ data }) => transformList(data));
    }

    static async download(distributionId) {
        return api.get(`/api/v2/distributions/${distributionId}/download`);
    }

    static async requeue(distributionId, params) {
        return api.post(`/api/v2/distributions/${distributionId}`, params);
    }
}

export { EsDistributionsService };
