/* eslint-disable class-methods-use-this */
import { notificationsApi } from './utils';
import { transformConversation } from './conversation/transform';

class ChannelService {
    #channel;

    #socket;

    #subscriptions = {};
    
    #getActiveChannel() {
        return notificationsApi.getNotificationsChannels({ includechannels: 'token' })
            .then(async (data) => {
                let channel;
                if (data?.entities?.length) {
                    [channel] = data.entities;
                } else {
                    channel = await notificationsApi.postNotificationsChannels();
                }
                return channel;
            });
    }

    async #initialize() {
        this.#channel = await this.#getActiveChannel();
        if (!this.#socket) {
            this.#socket = new WebSocket(this.#channel.connectUri);
        }
        this.#socket.onmessage = (message) => {
            const data = JSON.parse(message.data);
            const handler = this.#subscriptions[data.topicName];
            if (handler) {
                handler(data);
            }
        };
    }

    #getSubscriptionData() {
        notificationsApi.getNotificationsAvailabletopics();
        notificationsApi.getNotificationsChannelSubscriptions(this.#channel.id);
    }

    async #subscribeToTopic(topics, onMessage, clear) {
        if (!this.#channel) {
            await this.#initialize();
        }
        topics.forEach((topic) => {
            this.#subscriptions[topic] = onMessage;
        });
        this.#subscriptions[topics.join('!')] = onMessage;

        if (clear) {
            return notificationsApi.putNotificationsChannelSubscriptions(
                this.#channel.id,
                topics.map(id => ({ id }))
            );
        }
        return notificationsApi.postNotificationsChannelSubscriptions(
            this.#channel.id,
            topics.map(id => ({ id }))
        );
    }

    async subscribeToCalls(meId, onMessage) {
        const topics = [
            // `v2.users.${meId}.presence`
            // `v2.users.${meId}.conversations`,
            `v2.users.${meId}.conversations.calls`,
            `v2.users.${meId}.conversations.callbacks`
        ];

        const onBeforeMessage = ({ eventBody }) => {
            const call = transformConversation(eventBody);
            // Process incoming calls only
            if (call.agent?.isOnCall) {
                onMessage(call);
            }
        };
        return this.#subscribeToTopic(topics, onBeforeMessage, true);
    }
  
    async subscribeToConversationEnd(id, onMessage) {
        const topics = [
            `v2.detail.events.conversation.${id}.user.end`
        ];
        return this.#subscribeToTopic(topics, onMessage);
    }
}

const GnChannelService = new ChannelService();

export { GnChannelService };
