/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'library';
import { GnContactService, GnConversationService } from 'services';
import { transformConversation } from 'services/genesys/conversation/transform';

const initialState = {};

export const fetchGenesysUser = createAsyncThunk(
    'call/fetchGenesysUser',
    phone => GnContactService.searchByPhone(phone));

export const getActiveConversation = createAsyncThunk(
    'call/getActiveConversation',
    () => GnConversationService.getActiveConversation()
);

export const fetchConversation = createAsyncThunk(
    'call/fetchConversation',
    id => GnConversationService.get(id)
);

export const transferToRN = createAsyncThunk(
    'call/transferToRn',
    (...args) => GnConversationService.transferToRN(...args)
);

export const consultRN = createAsyncThunk(
    'call/consultRN',
    (...args) => GnConversationService.consultRN(...args)
);

export const transferToPhone = createAsyncThunk(
    'call/transferToPhone',
    (...args) => GnConversationService.transferToPhone(...args)
);

export const inviteExternal = createAsyncThunk(
    'call/inviteExternal',
    (...args) => GnConversationService.inviteExternal(...args)
);

export const inviteInternal = createAsyncThunk(
    'call/inviteInternal',
    (...args) => GnConversationService.inviteInternal(...args)
);

export const transferToInternalInterpreter = createAsyncThunk(
    'call/transferToInternalInterpreter',
    (...args) => GnConversationService.transferToInternalInterpreter(...args)
);

// eslint-disable-next-line consistent-return
const conversationReducerFn = (state, data) => {
    if (data.payload?.id && data.payload?.id !== state.id) {
        return data.payload;
    }
};

const callSlice = createSlice({
    name: 'call',
    initialState,
    reducers: {
        setCall: (state, action) => transformConversation(action.payload),
        clearCall: () => ({}),
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConversation.fulfilled, conversationReducerFn)
            .addCase(getActiveConversation.fulfilled, conversationReducerFn)
            .addCase(transferToRN.fulfilled, conversationReducerFn)
            .addCase(consultRN.fulfilled, conversationReducerFn)
            .addCase(transferToPhone.fulfilled, conversationReducerFn)
            .addCase(inviteExternal.fulfilled, conversationReducerFn)
            .addCase(inviteInternal.fulfilled, conversationReducerFn)
            .addCase(transferToInternalInterpreter.fulfilled, conversationReducerFn)
            .addCase(fetchGenesysUser.pending, (state) => {
                state.user = { loading: true };
            })
            .addCase(fetchGenesysUser.fulfilled, (state, action) => {
                state.user = action.payload;
            })
            .addCase(fetchGenesysUser.rejected, (state, { error }) => {
                message.error(error.message);
                state.user = { loading: false };
            });
    }
});

// Action creators are generated for each case reducer function
const {
    transformCall, update, setCall, clearCall
} = callSlice.actions;

export {
    transformCall, update, setCall, clearCall
};

export default callSlice.reducer;
