import { notification } from 'library';

const VERSION = process.env.REACT_APP_VERSION;

export const showError = (e) => {
    if (e.message) {
        const description = e.cause ? <ol>{e.cause.map(error => <li key={error}>{error}</li>)}</ol> : (e.description || '');
        if (e.config?.url === '/api/auth/login') {
            return e;
        }
        notification.error({
            message: e.message || 'Unable to load data.',
            description: (
                <>
                    {description}
                    <i>{e.config?.url || ''}</i>
                    <div style={{ fontSize: 9 }}>{e.stack || ''}</div>
                    <small style={{ float: 'right' }}>v. {VERSION}</small>
                </>
            ),
            duration: 2 * 60, // seconds
            showProgress: false
        });
    }
    return e;
};

export const catchError = (e) => {
    showError(e);
    return Promise.reject(e);
};
